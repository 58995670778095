import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bolet amb un capell d’uns 3 cm de diàmetre, aviat aplanat, amb el marge ondulat que s’aixeca un poc amb l’edat. Amb l’ajuda d’una lupa es poden observar una espècie de venes en relleu característiques al capell. Les làmines estan lliures, un poc ventrudes, de color groc pàl·lid que amb la caiguda de les espores tornen rosades. El peu és dret i d’un color groc molt vistós. Les espores són rosades en massa, de subgloboses a globoses, de 6-7 x 5-6 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      